import { defineStore } from "pinia";
import type { Response } from "@tengiva/services-api-layer/types/response";
import type { Pagination } from "@tengiva/services-api-layer/types/pagination";
import type { CatalogCollection, CatalogTextileItem, CollectionCatalogTextileItem } from "@/types/products";
import type { filters } from "@/types/filters.js";

export const useCollectionsStore = defineStore("collections", () => {
	const { viewCollectionsItemsSummaryMarketplace } = usePMApi();
	const { formatFiltersForQueryParams } = useFiltersStore();
	const { $sentry } = useNuxtApp();

	const router = useRouter();

	const collections = ref<{
		collections: CatalogCollection[];
		pagination?: Pagination;
	}>();

	async function setCollections(platformOrgId: string, page: number = 1) {
		const response = await fetchCollections(platformOrgId, page);

		collections.value = {
			collections: response?.results || [],
			pagination: response?.pagination,
		};
	}

	async function fetchCollections(platformOrgId: string, page: number) {
		try {
			const { response, error } = (await viewCollectionsItemsSummaryMarketplace(platformOrgId, {
				limit: 10,
				page,
			})) as Response<{
				results: CatalogCollection[];
				pagination: Pagination;
				additionalData: any;
			}>;

			if (error) throw error;

			if (response?.data) return response?.data;
		} catch (error) {
			$sentry.captureException(`Cannot fetch collections for platform, ${error}`);
		}
	}

	const order = ref<string>("");

	function setOrder(o: string) {
		order.value = o;
	}

	const filters = ref<filters>({});

	function setFilters(newFilters: filters) {
		filters.value = newFilters;

		router.push({ query: formatFiltersForQueryParams(filters.value) });
	}

	const collection = ref<CatalogCollection>();

	async function setCollection(collectionId: string) {
		const response = await fetchCollection(collectionId);

		collection.value = response;
	}

	async function fetchCollection(collectionId: string) {
		const { viewCollectionMarketplace } = usePMApi();
		try {
			const { response, error } = await viewCollectionMarketplace(collectionId);

			if (error) throw error;

			if (response?.data) return response?.data;
		} catch (error) {
			$sentry.captureException(`Cannot fetch collection ${collectionId} for platform, ${error}`);
		}
	}

	function flattenCollectionsTextiles(collection: any) {
		return (
			collection?.collections_textiles?.reduce((acc: CatalogTextileItem[], curr: CatalogCollection) => {
				const textileItemsData = curr.collections_textile_items.map((item: CollectionCatalogTextileItem) => {
					return {
						...item.textiles_item,
						textile: {
							...curr.textile,
							organization_name: collection.organization_names?.[curr.textile.organization_id],
						},
					};
				});
				return acc.concat(textileItemsData || []);
			}, []) || []
		);
	}

	return {
		collections,
		setCollections,
		collection,
		setOrder,
		setFilters,
		setCollection,
		flattenCollectionsTextiles,
	};
});
